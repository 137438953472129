import type { DataLayerPageView, DataLayerPageViewExternal } from "./dataLayerEvents.types";

const submitPageViewEvent = ({
  pageReferrer,
  contentGroup,
  pageLanguage,
  vinType,
  vinRecords,
  reportCombination,
}: DataLayerPageView): void => {
  const eventParameters: DataLayerPageViewExternal = {
    event: "page_view",
    page_location: window.location.href,
    page_referrer: pageReferrer,
    content_group: contentGroup,
    page_language: pageLanguage,
    vin_type: vinType,
    vin_records: vinRecords,
    report_combination: reportCombination,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

export const dataLayerEvents = {
  submitPageViewEvent,
};
