import { RecordCheckData, VinType } from "../dataLayerEvents.types";

const getRecordCheckData = (recordCheckStore): RecordCheckData => {
  const vin = recordCheckStore.isEncrypted ? recordCheckStore.encryptedQuery : recordCheckStore.query;
  return {
    vinType: vin && vin.length < 10 ? VinType.LICENSE_PLATE : VinType.VIN,
    vinRecords: recordCheckStore.reportDataTotal,
    reportCombination: recordCheckStore.reportCombination,
  };
};

export const dataLayerUtil = {
  getRecordCheckData,
};
